import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import eventBus from "app/services/eventBus";
import { NotificationArea, NotificationItem } from "./notifications";
import Footer from "./Footer";
import setupLogRocketReact from 'logrocket-react';
import LogRocket from 'logrocket';

const Layout = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    LogRocket.init('kcf5v3/aerocharter');
    LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
      name: 'test',
      email: 'test@example.com',
      subscriptionType: 'pro'
    });
    setupLogRocketReact(LogRocket)
    // This is an example script - don't forget to change it!
  }, [])

  useEffect(() => {
    const notificationsSubscription = eventBus.subscribe(
      "NOTIFICATION",
      (notification) => {
        if (notification.duration !== undefined) {
          setTimeout(() => {
            eventBus.publish("CLEAN_NOTIFICATIONS");
          }, notification.duration);
        }
        setNotifications([notification]);
      }
    );

    const cleanNotifications = eventBus.subscribe("CLEAN_NOTIFICATIONS", () => {
      setNotifications([]);
    });
    // ? unsubscribes the event listener when the component unmounts
    return () => {
      notificationsSubscription.unsubscribe();
      cleanNotifications.unsubscribe();
    };
  }, [eventBus, setNotifications]);

  return (
    <>
      <Helmet>
        <meta name="viewport"></meta>
      </Helmet>
      <div className="bg-white mx-auto max-w-7xl px-6 grid grid-rows-1 space-y-16">
        <div className=" lg:px-4">{children}</div>
      </div>
      {notifications && notifications.length > 0 && (
        <NotificationArea>
          {notifications.map((notification, index) => {
            return (
              <NotificationItem
                key={notification.type}
                itemKey={index}
                onClose={(itemKey) => {
                  // ! remove the desired item on click
                  setNotifications(
                    notifications.filter((_, key) => key !== itemKey)
                  );
                }}
                type={notification.type}
                title={notification.title}
                notification={notification.message}
              />
            );
          })}
        </NotificationArea>
      )}
      <Footer />
    </>
  );
};

export default Layout;
