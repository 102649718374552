import React from "react";
import Navbar from "components/global/navbar";
import Layout from "components/global/layout";
const avisoPrivacidad = [
  {
    title: "1. Responsable de la protección de los datos personales.",
    description:
      "La marca comercial VIVA CARGO POWERED BY AEROCHATER. Con domicilio para oír y recibir notificaciones en Avenida Paseo de las Palmas No. 215 Oficina 503 Lomas de Chapultepec V Sección, Alcaldía Miguel Hidalgo, C.P. 11000, en la Ciudad de México, CDMX, es responsable del tratamiento y protección de los datos personales.",
    list: [],
  },
  {
    title: "2. Información sobre el oficial de privacidad y cómo contactarlo.",
    description:
      "El oficial de privacidad es la persona al interior de XXXXXXX que, en cumplimiento con el artículo 30 de la LFPD ha sido formalmente designado para:",
    list: [
      "• Atender las solicitudes de los titulares para el ejercicio de sus derechos.",
      "• Fomentar y velar por la protección de los datos personales que tenemos en posesión.",
      "Para todo lo relacionado con el tratamiento y protección de datos personales, usted podrá contactarse con el oficial de privacidad enviando un correo electrónico a la siguiente dirección: lfpd@aerocharter.com.mx ",
    ],
  },
  {
    title: "3. Datos personales que se recaban.",
    description:
      "Para realizar las finalidades señaladas en el numeral 4 (cuatro) del presente aviso de privacidad, se recabarán las siguientes categorías de datos personales:(i) Identificación; (ii) contacto; (iii) patrimoniales; y (iv) financieros.",
    list: [
      "3.1. Datos Personales Sensibles.",
      "No se recaban datos personales sensibles de los clientes.",
      "3.2.-Datos Personales de Terceros.",
      "Para los datos personales de terceros que nos proporciona, deberá contar previamente con el consentimiento del titular de dichos datos e informarle el sitio en donde podrá encontrar y conocer el presente aviso de privacidad integral. La marca comercial VIVA CARGO POWERED BY AEROCHARTER cumplirá en todo momento con las disposiciones de la LFPD y su Reglamento en cuanto al tratamiento de datos personales obtenidos de forma indirecta.",
    ],
  },
  {
    title: "4. Finalidades del tratamiento de los datos personales recabados.",
    description:
      "Los datos personales de los clientes son recabados para las siguientes finalidades primariaes y secundarias:",
    list: [
      "a) Primarias. — Finalidades que dan origen y son necesarias para la relación jurídica entre el cliente y la marca comercial VIVA CARGO POWERED BY AEROCHARTER",
      "• Identificarlo y contactar al cliente.",
      "• Proporcionar la información que el cliente solicite, realizar cotizaciones, notificar demoras, cancelaciones y estatus general del envío; así como, seguimiento, actualización y confirmación de los servicios contratados.",
      "• Realizar contratos.",
      "• Registro de los datos para el trasporte de la carga en sistema para su proceso operativo.",
      "• Reembolsos y trámites de bonificación por errores operativos, condonaciones, reexpediciones y desistimientos.",
      "• En caso de alguna reclamación, integrar el expediente correspondiente y procesar la misma.",
      "• Elaboración de facturas.",
      "• Cumplimiento de requerimientos y obligaciones normativas con las autoridades competentes, así como atención a requerimientos o resoluciones de autoridades gubernamentales o judiciales en México o en el extranjero.",
      "• Envío de los datos del remitente y consignatario del embarque con anticipación a diferentes autoridades de los gobiernos.",
      "• Videovigilancia y grabaciones, por razones de seguridad.",
      "b) Secundarias. — Finalidades distintas y que no dan origen a la relación jurídica entre usted y la marca comercial VIVA CARGO POWERED BY AEROCHARTER, pero que no son de suma importancia y utilidad para:",
      "• Informar y/u ofrecer productos, servicios y promociones de empresas del  grupo corporativo (a través de la marca comercial VIVA CARGO POWERED BY AEROCHARTER o contactándolo directamente dichas empresas).",
      "• Mercadotecnia y publicidad.",
      "• Prospección comercial en conjunto con empresas afiliadas a la marca comercial VIVA CARGO POWERED BY AEROCHARTER",
      "• Encuestas, evaluación y seguimiento de los servicios de carga brindados.",
      "4.1. Negativa al tratamiento de sus datos personales para finalidades secundarias.",
      "En caso de que no desee que sus datos personales sean tratados para las finalidades secundarias mencionadas, o alguna(s) de ellas, puede negarnos su consentimiento desde este momento enviando su solicitud al oficial de privacidad, quien le indicará el procedimiento a seguir para ejercer su derecho. Su negativa en este sentido, no podrá ser motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.",
    ],
  },
  {
    title: "5. Transferencia de sus datos personales.",
    description:
      "Para el cumplimiento de las finalidades primarias señaladas en el numeral 4 (cuatro) del presente aviso de privacidad, los datos personales podrán ser transferidos a las siguientes personas físicas o morales, sin que legalmente se requiera su consentimiento en términos del artículo 37 de la LFPD.",
    list: [
      "• Servicio de Administración Tributaria.— para la elaboración del comprobante fiscal digital.",
      "• Empresas aseguradoras.— Con la finalidad de dictaminar las reclamaciones y en caso de proceder el pago de las mismas.",
      "• Aerolíneas y empresas de transporte.- cuando la carga de lo que se pretende enviar es superior a la capacidad de los aviones de la marca comercial VIVA CARGO POWERED BY AEROCHARTER o cuando se requiere conexión aérea o terrestre hacia algún aeropuerto o ciudad más allá de la red operada por la marca comercial VIVA CARGO POWERED BY AEROCHARTER , con la finalidad de prestar el servicio contratado.",
      "• Empresas de servicios aeroportuarios (GHA).— cuando se requieren maniobras, custodia y almacenaje de la mercancía en cualquiera de los aeropuertos (origen, destino o tránsito).",
      "• Empresas de comercialización (GSA’s) nacionales o extranjeras.- responsables de la venta y cobranza de los servicios de la marca comercial VIVA CARGO POWERED BY AEROCHARTER ; así como proporcionar el seguimiento a los embarques.",
      "• Empresas de mensajería y/o paquetería.-, En este caso se transmiten datos personales de identificación y contacto para hacerle llegar el envío a la dirección que usted especifique (recolección y entrega a domicilio).",
      "• Autoridades gubernamentales nacionales y/o extranjeras.- Con la finalidad de cumplir con las disposiciones legales correspondientes, atender sus requerimientos sobre el registro de la carga y por motivos de seguridad; así como para el esclarecimiento de incidentes o ejercicio de algún derecho.",
      "• Autoridades judiciales en México o en el extranjero para atender requerimientos que pudieran resultar en su interés.- Esto implica que puede ser contactado por la autoridad correspondiente, o quien esta haya designado, a efecto de informarle sobre: (i) procesos legales de la marca comercial VIVA CARGO POWERED BY AEROCHARTER en los que usted pudiera tener algún interés; (ii) la forma de ejercer sus derechos en caso de tenerlos (sin limitación a que usted pueda ejercerlos de la manera que usted crea necesario o conveniente).",
      "• Sociedades que forman parte de la marca comercial VIVA CARGO POWERED BY AEROCHARTER, que operen bajo los mismos procesos y políticas internas.- Con la finalidad de brindarle un mejor servicio y cumplir con la normativa interna. Estas sociedades establecerán las medidas de seguridad administrativas, técnicas y físicas que nos permitan proteger sus datos personales contra daño, pérdida, alteración, destrucción o un uso distinto al señalado en el presente aviso de privacidad.",
      "• Médicos, paramédicos o prestadores de servicios sanitarios, en caso de que ocurriera algún accidente, emergencia médica o situación de salud.- Con la finalidad de poderlo asistir.",
      "• En caso de que la marca comercial VIVA CARGO POWERED BY AEROCHARTER lleve a cabo una fusión, desincorporación, venta, liquidación, quiebra o cualquier otro tipo de transacción que implique la transferencia de la totalidad o parte de sus activos o negocios por otra empresa que opere bajo los mismos procesos y políticas internas, pasando a formar parte de su grupo corporativo, podremos transferir a dicho tercero adquirente sus datos personales con la finalidad que le informe y ofrezca sus productos y/o servicios.",
      "Asimismo, con el consentimiento, se podrán realizar las siguientes transferencias de los datos personales:",
      "• En caso de que la marca comercial VIVA CARGO POWERED BY AEROCHARTER lleve a cabo una fusión, venta, desincorporación, liquidación, quiebra o cualquier otro tipo de transacción que implique la transferencia de la totalidad o parte de sus activos o negocios por una empresa o entidad que NO opere bajo los mismos procesos y políticas internas, con la finalidad de que le informe y ofrezca sus productos y/o servicios.",
      "• En caso de que, derivado de algún proceso legal, ya sea de liquidación corporativa o quiebra judicial, en México o en el extranjero, se tenga la necesidad de transferir los activos y bases de datos a los acreedores que determine la autoridad, con la finalidad de cumplir con las obligaciones legales.",
      "Para poder llevar a cabo o realizar estas dos últimas transferencias, y solo en el evento en que se diera alguno de dichos supuestos, se solicitaría previamente consentimiento expreso y por escrito del cliente en los términos de la LFPD.",
    ],
  },
  {
    title: "6. Derechos ARCO.",
    description:
      "En los términos de la normativa aplicable, usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de los registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.",
    list: [
      "Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través de un correo electrónico dirigido al oficial de privacidad (la marca comercial VIVA CARGO POWERED BY AEROCHARTER), quien le informará sobre el procedimiento y requisitos para el ejercicio de esos derechos, plazos de respuesta, la forma en que haremos efectivo su derecho, y atenderá cualquier duda, queja o comentario que tenga al respecto.",
    ],
  },
  {
    title: "7. Mecanismos y procedimiento para revocar su consentimiento.",
    description:
      "En los términos de la normativa aplicable, usted puede revocar el consentimiento que en su caso nos haya otorgado. Sin embargo, es importante que tenga en cuenta que no en todos los casos será procedente su solicitud o podremos concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.",
    list: [
      "Para revocar su consentimiento deberá presentar su solicitud a través de un correo electrónico dirigido al oficial de privacidad, quien le informará sobre el procedimiento y requisitos para el ejercicio de este derecho, plazos de respuesta, la forma en que haremos efectivo su derecho, y atenderá cualquier duda, queja o comentario que tenga al respecto.",
    ],
  },
  {
    title:
      "8. Opciones con las que cuenta el titular para limitar el uso o divulgación de sus datos Personales.",
    description:
      "Además del procedimiento y ejercicio de los derechos contemplados en los numerales 6 (seis) y 7 (siete) del presente aviso de privacidad, usted puede limitar el uso o divulgación de sus datos personales de las siguientes formas:",
    list: [
      "• Para las finalidades de mercadotecnia y publicidad, puede manifestar su negativa a través del marcado en la casilla “No deseo recibir correos”, misma que se encuentra en la parte inferior de los correos electrónicos que le hacemos llegar.",
      "• Enviar un correo electrónico al oficial de privacidad en el que solicite su inscripción al “Listado de Exclusión Publicitaria de la marca comercial VIVA CARGO POWERED BY AEROCHARTER”, con el objeto de que no utilicemos su información personal para finalidades promocionales, publicitarias o mercadotécnicas.",
      "• Inscribirse en el Registro Público para Evitar Publicidad de la Procuraduría Federal del Consumidor (PROFECO). Respecto de esta última opción, puede obtener información contactándose directamente con la PROFECO o ingresando a la siguiente liga: www.rpc.profeco.gob.mx",
    ],
  },
  {
    title: "9. Uso de cookies y web beacons.",
    description:
      "Hacemos de su conocimiento que contamos con tecnologías electrónicas (cookies y web beacons), que nos permiten recabar datos de su actividad de manera electrónica y simultánea, al tiempo que usted hace contacto con nosotros a través de la  página de internet: la marca comercial VIVA CARGO POWERED BY AEROCHARTER. Algunas de las cookies que utilizamos son necesarias para que usted pueda navegar en el sitio web, así como hacer uso de sus funciones.",
    list: [
      "Los datos que recabamos son los siguientes:",
      "• Información del tipo de explorador que utiliza.",
      "• Información del horario y tiempo que permaneció en la página.",
      "• Secciones consultadas en la página.",
      "• Información de las páginas que visitó previo de ingresar a: https://www.vivaaerobus.com/es-mx/servicios/viva-cargo ",
      "• Información de las páginas que visitó posterior a haber ingresado a: https://www.vivaaerobus.com/es-mx/servicios/viva-cargo ",
      "• Su dirección IP.",
      "Finalidades:",
      "• Estas tecnologías las utilizamos para registrar información sobre las opciones que haya elegido previamente el usuario, lo cual nos permite darle una mejor experiencia de navegación, así como para entender la eficacia del contenido y saber qué es lo que interesa a los usuarios, con el propósito de mejorar el funcionamiento de este sitio web.",
      "• Además, utilizamos web beacons o píxeles de seguimiento para contar el número de visitantes, así como las denominadas cookies de rendimiento para realizar el seguimiento de cuántos usuarios, de manera individual, tienen acceso a esta página web, con qué frecuencia y desde que países y tipo de explorador y sistema operativo nos visitan. Esta información es utilizada únicamente con fines estadísticos y no se tiene como propósito utilizarla para identificar personalmente a los usuarios. Esta página Web no utiliza las denominadas Targeting Cookies para entregar publicidad específica dirigida a los visitantes de este sitio web. Los datos recabados serán para el uso exclusivo de la marca comercial VIVA CARGO POWERED BY AEROCHARTER, por lo tanto, dichos datos no serán sujetos de transferencias nacionales o internacionales. En todo momento usted puede deshabilitar el uso de estas tecnologías. Podrá consultar dicho procedimiento en:",
      "Desde Internet Explorer (en Windows):",
      "http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies",
      "Firefox:",
      "https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we",
      "Google Chrome:",
      "http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647",
      "Opera:",
      "http://help.opera.com/Windows/11.50/es-ES/cookies.html",
      "Safari en Mac:",
      "http://www.apple.com/legal/privacy/es/cookies/",
    ],
  },
  {
    title: "10. Modificaciones al presente aviso de privacidad.",
    description:
      "Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, atendiendo a novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de servicios o productos. La versión actualizada estará disponible al público a través de los siguientes medios:",
    list: [
      "• Anuncios visibles en establecimientos.",
      "• En la página de Internet, la cual sugerimos visite frecuentemente.",
      "• Se la podremos hacer llegar al último correo electrónico que nos haya proporcionado.",
      "El procedimiento con relación a la notificación vía Internet, es el siguiente: (i) Ingrese a la página de Internet de la marca comercial VIVA CARGO POWERED BY AEROCHARTER. En caso de haber modificaciones o actualizaciones al presente aviso de privacidad, habrá una notificación que se lo haga saber; (ii) acceda a la liga de aviso de privacidad; (iii) ahí encontrará la versión vigente con la fecha de la más reciente actualización.",
    ],
  },
  {
    title: "11. Tratamiento indebido de sus datos personales.",
    description:
      "Si usted considera que su derecho a la protección de los datos personales ha sido lesionado por alguna conducta u omisión de alguna parte, o presume alguna violación a las disposiciones previstas en la LFPD, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI). Para mayor información, le sugerimos visitar su página oficial de Internet www.inai.org.mx",
    list: [
      "• Anuncios visibles en establecimientos.",
      "• En la página de Internet, la cual sugerimos visite frecuentemente.",
      "• Se la podremos hacer llegar al último correo electrónico que nos haya proporcionado.",
      "El procedimiento con relación a la notificación vía Internet, es el siguiente: (i) Ingrese a la página de Internet de la marca comercial VIVA CARGO POWERED BY AEROCHARTER. En caso de haber modificaciones o actualizaciones al presente aviso de privacidad, habrá una notificación que se lo haga saber; (ii) acceda a la liga de aviso de privacidad; (iii) ahí encontrará la versión vigente con la fecha de la más reciente actualización.",
    ],
  },
];

const AvisoPrivacidad = () => {
  return (
    <>
      <Navbar />
      <Layout>
        <div className="mt-6 text-gray-600 text-sm">
          <h1 className="text-gray-700 text-center font-extrabold text-xl mb-4">
            Aviso de Privacidad
          </h1>
          <p>
            En cumplimiento con la Ley Federal de Protección de Datos Personales
            en Posesión de los Particulares (en lo sucesivo “LFPD”), se pone a
            disposición el presente aviso de privacidad, y al respecto se
            informa lo siguiente:
          </p>
          <ul className="list-outside">
            {avisoPrivacidad.map((item) => (
              <li
                key={item.title}
                className="text-gray-600 my-4 flex items-center text-justify "
              >
                <div>
                  <h3 className="text-gray-800 font-bold text-sm py-2">
                    {item.title}
                  </h3>
                  {item.description}
                  {/* !Elementos de lista anidados */}
                  <ul className="list-outside">
                    {item.list.map((item) => (
                      <li key={item} className="py-2">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    </>
  );
};

export default AvisoPrivacidad;
