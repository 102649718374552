import * as React from "react";
import { useState, Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/outline";

const NotificationItem = ({ type, title, notification, itemKey, onClose }) => {
  const [show] = useState(true);

  function handleClose() {
    onClose(itemKey);
  }

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === "success" && (
                <div className="w-6 h-6 relative">
                  <CheckCircleIcon
                    className=" absolute h-6 w-6 text-green-200 animate-ping duration-1000"
                    aria-hidden="true"
                  />
                  <CheckCircleIcon
                    className=" absolute h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
              )}
              {type === "error" && (
                <div className="w-6 h-6 relative">
                  <XCircleIcon
                    className="absolute h-6 w-6 text-red-200 animate-ping duration-1000"
                    aria-hidden="true"
                  />
                  <XCircleIcon
                    className="absolute h-6 w-6 text-red-400 "
                    aria-hidden="true"
                  />
                </div>
              )}
              {type === "info" && (
                <div className="w-6 h-6 relative">
                  <InformationCircleIcon
                    className="absolute h-6 w-6 text-blue-200 animate-ping duration-1000"
                    aria-hidden="true"
                  />
                  <InformationCircleIcon
                    className="absolute h-6 w-6 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <div className="text-sm font-medium text-gray-900">{title}</div>
              <div className="mt-1 text-sm text-gray-500">{notification}</div>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  handleClose(itemKey);
                }}
              >
                <span className="sr-only">Cerrar</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

const NotificationArea = ({ children }) => {
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 sm:p-6 sm:items-center
                transition-opacity duration-300 ease-in-out bg-gray-700 bg-opacity-70 pointer-events-auto z-50"
      >
        <div className="w-full flex flex-col items-center space-y-4 ">
          {children}
        </div>
      </div>
    </>
  );
};

export { NotificationArea, NotificationItem };
